
    // ONLY IMPORT FILES THAT CONTAIN SCSS VARIABLES ONLY, and nothing else.
    @import "src/styles/variables.scss";
  
.root {
  flex: 1;
  max-width: calc(100vw - 1rem);
}

.controls {
  top: auto !important;
  bottom: -30px;
  right: 0;
  left: auto !important;
  width: 60px;
}

.control {
  border: none;
  box-shadow: none;
  background: transparent;
  width: 3rem;
}

.indicator {
  width: 8px !important;
  height: 8px !important;
  transition: width 250ms ease;
  background-color: grey;

  &[data-active] {
    width: 20px !important;
    background-color: $color-rainforest-primary;
  }
}
