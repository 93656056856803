
    // ONLY IMPORT FILES THAT CONTAIN SCSS VARIABLES ONLY, and nothing else.
    @import "src/styles/variables.scss";
  
.body,
.footer {
  padding: $space-sm;
  border: $border-strong;
}

.body {
  border-top-left-radius: $border-radius-large;
  border-top-right-radius: $border-radius-large;
}

.itemDetails {
  --stack-gap: 4px !important;
}

.footer {
  background-color: $color-rainforest-light;
  border-top: none;
  border-bottom-left-radius: $border-radius-large;
  border-bottom-right-radius: $border-radius-large;
  margin-bottom: $space-sm;
}

.secondaryText {
  color: $color-accent-newengland;
}
